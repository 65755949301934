// Literally looks like a browser window.
.browser {
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 18px rgba(black, 0.14);
  margin: auto;
  max-width: 1260px;
  overflow: hidden;

  &-header {
    align-items: center;
    background: linear-gradient(#ffffff 10%, #e7e7e7);
    display: flex;
    height: 36px;
    padding: 0 12px;

    @include mq($from: desktop) {
      padding: 0 12px;
      height: 40px;
    }

    &-dot {
      background: #c4c4c4;
      border-radius: 50%;
      margin-left: 8px;
      height: 12px;
      width: 12px;

      @include mq($from: desktop) {
        height: 14px;
        width: 14px;
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &-window {
    align-items: stretch;
    display: flex;
    flex: 1;
    height: 164px;

    @include mq($from: desktop) {
      height: 240px;
    }
  }
}
