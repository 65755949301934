.projects {
  padding: 60px 0;

  &-header {
    margin-bottom: 18px;

    @include mq($from: desktop) {
      margin-bottom: 48px;
    }
  }

  &-box {
    margin-bottom: 48px;
    position: relative;
    transform: translateY(25%);
    transition: all 1s var(--ease-out-cubic);
    z-index: 1;

    &.js-scroll-animate {
      transform: none;

      &::after {
        transform: scaleY(0);
      }
    }

    @include mq($from: desktop) {
      align-items: center;
      display: flex;
      margin-bottom: 180px;
    }

    &--reverse {
      flex-direction: row-reverse;

      .projects-img {
        @include mq($from: desktop) {
          margin-left: 0;
          margin-right: 84px;
        }
      }
    }

    &--sunset {
      background: linear-gradient(354.05deg, #ff6f6f 29.89%, #f9d749 100%);
    }

    &--cyan {
      background: linear-gradient(0deg, #69ffe4 0%, #9a19ff 100%);
    }

    &--redsky {
      background: linear-gradient(360deg, #9451ff 0%, #f94949 100%);
    }

    &::after {
      background: var(--background-color);
      content: '';
      height: 100%;
      left: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      transform-origin: center bottom;
      transition: all 1s var(--ease-in-out-cubic);
      width: 100%;
      z-index: 2;
    }
  }

  &-info {
    flex: 1;
    height: 224px;
    padding: 24px;

    @include mq($from: desktop) {
      height: auto;
      padding: 0;
    }
  }

  &-name {
    font-size: 24px;
    margin-bottom: 4px;

    @include mq($from: desktop) {
      font-size: 40px;
    }
  }

  &-desc {
    font-size: 16px;
    margin-bottom: 18px;

    @include mq($from: desktop) {
      font-size: 18px;
    }
  }

  &-tech-list {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    margin-bottom: 36px;

    &-item {
      background: rgba(white, 0.2);
      border-radius: 2px;
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 8px;
      margin-right: 12px;
      padding: 0 6px;
      white-space: nowrap;

      @include mq($from: desktop) {
        margin-bottom: 0;
      }
    }
  }

  &-img {
    border-radius: 10px;
    flex: 1;
    left: 0;
    opacity: 0.3;
    padding: 12px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;

    @include mq($from: desktop) {
      margin-left: 72px;
      opacity: 1;
      padding: 30px;
      position: static;
      width: auto;
    }

    @include mq($from: wide) {
      margin-left: 84px;
    }
  }

  &-links {
    display: none;

    @include mq($from: desktop) {
      align-items: center;
      display: flex;

      &-link {
        color: white;
        margin-left: 36px;
        text-decoration: none;

        &:first-child {
          margin-left: 0;
        }

        span {
          display: inline-block;
          margin-left: 12px;
          position: relative;

          &::before {
            background: white;
            bottom: 0;
            content: '';
            height: 1px;
            left: 0;
            position: absolute;
            transform-origin: left center;
            transform: scaleX(0);
            transition: transform 0.35s var(--ease-out-cubic);
            width: 100%;
          }
        }

        svg {
          height: 24px;
          width: 24px;
          transition: transform 0.35s var(--ease-out-cubic);
        }

        &:hover {
          svg {
            transform: rotateY(180deg);
          }

          span::before {
            transform: none;
          }
        }
      }
    }
  }
}
