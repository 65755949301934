.credits {
  background: #040404;
  border: 2px solid white;
  border-radius: 8px;
  bottom: 48px;
  opacity: 0;
  position: absolute;
  padding: 18px;
  transform: translateY(6%);
  transition: all 0.25s var(--ease-out-cubic);

  &-name {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 2px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
