.react-camera {
  background: #7a08fa;
  flex: 1;
  padding: 24px;

  @include mq($from: desktop) {
    padding: 48px;
  }

  svg {
    display: block;
    margin: auto;
    width: 72px;

    @include mq($from: desktop) {
      width: 90px;
    }
  }

  &-person {
    @include mq($from: desktop) {
      transform-origin: center;
    }
  }
}
