.common {
  &-wrapper {
    flex: 1;
    margin: 0 auto;
    padding: 0 30px;

    @include mq($from: tablet) {
      max-width: 660px;
    }

    @include mq($from: desktop) {
      max-width: 924px;
    }

    @include mq($from: largeDesktop) {
      max-width: 1080px;
    }

    @include mq($from: wide) {
      max-width: 1157px;
    }
  }

  &-section-title {
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 1.5px;
    text-transform: uppercase;

    @include mq($from: tablet) {
      font-size: 14px;
    }
  }
}
